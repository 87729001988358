<template>
  <DialogVersion3 :visible.sync="visible" visibleFooter needFullscreen>
    <el-form label-position="top">
      <el-form-item :label="$t('common.field.bankName')">
        <span>{{ bankName }}</span>
        <img v-show="bankName" class="dialog-true" src="@/assets/images/dialog/dialog_true.png" alt />
      </el-form-item>
      <el-form-item :label="$t('common.field.bankCardNumber')">
        <span>{{ bankCardNumber }}</span>
        <img v-show="bankCardNumber" class="dialog-true" src="@/assets/images/dialog/dialog_true.png" alt />
      </el-form-item>
      <el-form-item :label="$t('common.field.bankBranch')">
        <span>{{ bankBranch }}</span>
        <img v-show="bankBranch" class="dialog-true" src="@/assets/images/dialog/dialog_true.png" alt />
      </el-form-item>
      <el-form-item :label="$t('common.field.cardHolderName')">
        <span>{{ cardHolderName }}</span>
        <img v-show="cardHolderName" class="dialog-true" src="@/assets/images/dialog/dialog_true.png" alt />
      </el-form-item>
      <el-form-item :label="$t('common.field.reservedMobileNumber')">
        <span>{{ reservedMobileNumber }}</span>
        <img v-show="reservedMobileNumber" class="dialog-true" src="@/assets/images/dialog/dialog_true.png" alt />
      </el-form-item>
      <el-form-item :label="$t('common.field.nationalId')">
        <span>{{ nationalId }}</span>
        <img v-show="nationalId" class="dialog-true" src="@/assets/images/dialog/dialog_true.png" alt />
      </el-form-item>
      <el-form-item :label="$t('common.field.cardPhoto')">
        <img class="cardPhoto" :src="cardPhoto" alt />
        <img v-show="cardPhoto" class="dialog-true" src="@/assets/images/dialog/dialog_true.png" alt />
      </el-form-item>
    </el-form>
  </DialogVersion3>
</template>

<script>
import DialogVersion3 from '@/components/dialog/v3/Dialog';
import { apiGetCardDetials } from '@/resource';

export default {
  name: 'UnionpayDetails',
  components: { DialogVersion3 },
  props: { display: Boolean, cardId: Number },
  data() {
    return {
      bankName: '',
      bankCardNumber: '',
      bankBranch: '',
      cardHolderName: '',
      reservedMobileNumber: '',
      nationalId: '',
      cardPhoto: '',
      visible: false
    };
  },
  watch: {
    display(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.$emit('update:display', bool);
    },
    cardId() {
      this.queryCard().then(card => {
        console.log('card', card);
        this.bankName = card.bank_name;
        this.bankCardNumber =
          card.card_number.substr(0, 6) + '******' + card.card_number.substr(card.card_number.length - 4);
        this.bankBranch = card.branch_name;
        this.cardHolderName = card.card_holder_name;
        this.reservedMobileNumber = card.card_phone_number;
        this.nationalId = card.national_id_card;
        this.cardPhoto = card.card_photo_path;
      });
    }
  },
  methods: {
    queryCard() {
      return apiGetCardDetials({ cardId: this.cardId, cardType: 'UNIONPAY_CARD_CPS' }).then(resp => {
        console.log(resp);
        if (resp.data.code == 0) {
          return resp.data.data;
        } else {
          return [];
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/payment/detail.scss';
</style>
