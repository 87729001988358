<template>
  <DialogVersion3 :visible.sync="visible" :visibleFooter="true">
    <el-form label-position="top">
      <el-form-item :label="$t('common.field.name')">
        <span v-html="name"></span>
        <img class="dialog-true" src="@/assets/images/dialog/dialog_true.png" alt />
      </el-form-item>
      <el-form-item :label="$t('common.field.ccNum')">
        <span>{{ cardNumber }}</span>
        <img class="dialog-true" src="@/assets/images/dialog/dialog_true.png" alt />
      </el-form-item>
      <el-form-item :label="$t('common.field.expDate')">
        <span v-html="expireDate"></span>
        <img class="dialog-true" src="@/assets/images/dialog/dialog_true.png" alt />
      </el-form-item>
    </el-form>
  </DialogVersion3>
</template>
<script>
import DialogVersion3 from '@/components/dialog/v3/Dialog';

export default {
  name: 'Detail',
  components: { DialogVersion3 },
  props: {
    display: Boolean,
    name: String,
    creditCardNumber: String,
    expireDate: String
  },
  data() {
    return {
      visible: false
    };
  },
  watch: {
    display(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.$emit('update:display', bool);
    }
  },
  computed: {
    cardNumber: function() {
      return (
        this.creditCardNumber.substring(0, 4) +
        ' ' +
        this.creditCardNumber.substring(4, 8) +
        ' ' +
        this.creditCardNumber.substring(8, 12) +
        ' ' +
        this.creditCardNumber.substring(12)
      );
    }
  },
  methods: {
    close() {
      this.$emit('update:display', false);
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/components/payment/detail.scss';
</style>
