<template>
  <el-form
    class="unionPayRegister"
    label-position="top"
    :model="unionPayForm"
    status-icon
    :rules="unionPayRules"
    ref="unionPayForm"
  >
    <div class="form-middle">
      <div class="name-bank">
        <el-form-item :label="$t('common.field.bankName')" prop="bankName">
          <el-select
            id="bankName"
            v-model="unionPayForm.bankName"
            :placeholder="$t('common.field.select')"
            data-testid="bankName"
          >
            <el-option
              v-for="(list, index) in bankNameLists"
              :key="index"
              :label="list"
              :value="list"
              :data-testid="list"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="card-holder-name">
        <el-form-item :label="$t('common.field.cardHolderName')" prop="cardHolderName">
          <el-input id="name" maxlength="128" v-model="unionPayForm.cardHolderName"></el-input>
        </el-form-item>
      </div>
    </div>
    <div class="form-rigister">
      <ul class="clearfix">
        <li>
          <el-form-item :label="$t('common.field.nationalId')" prop="nationalId">
            <el-input id="nationalId" maxlength="20" v-model="unionPayForm.nationalId"></el-input>
          </el-form-item>
        </li>
        <li>
          <el-form-item :label="$t('common.field.bankCardNumber')" prop="cardNumber">
            <el-input id="cardNumber" maxlength="20" v-model="unionPayForm.cardNumber"></el-input>
          </el-form-item>
        </li>
        <li>
          <el-form-item :label="$t('common.field.reservedMobileNumber')" prop="cardPhoneNumber">
            <el-input id="cardPhoneNumber" maxlength="20" v-model="unionPayForm.cardPhoneNumber"></el-input>
          </el-form-item>
        </li>
        <li class="branch-name">
          <el-form-item :label="$t('common.field.bankBranch')">
            <el-col :span="11" class="mb-1">
              <el-form-item prop="province">
                <el-select
                  id="province"
                  v-model="unionPayForm.province"
                  placeholder="—— 省 ——"
                  @change="provinceNameChange"
                  data-testid="province"
                >
                  <el-option
                    v-for="(item, indx) in provinceNameList"
                    :key="indx"
                    :label="item.label"
                    :value="item.label"
                    :data-testid="`province_${item.value}`"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11" :offset="2" class="mb-1">
              <el-form-item prop="city">
                <el-select id="city" v-model="unionPayForm.city" placeholder="—— 市 ——" data-testid="city">
                  <el-option
                    v-for="(item, indx) in cityNameList"
                    :key="indx"
                    :label="item"
                    :value="item"
                    :data-testid="`city_${indx}`"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item prop="branchName">
                <el-input
                  id="branchName"
                  v-model="unionPayForm.branch"
                  :placeholder="$t('common.field.bankBranch')"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
        </li>
        <li>
          <div class="info">
            <p>{{ this.$t('common.field.updateCardPhoto') }}</p>
          </div>
          <el-form-item prop="cardPhotoFile" class="center">
            <vUpload :limit="1" v-on:updateFileInfo="updateFileInfo"> </vUpload>
          </el-form-item>
        </li>
      </ul>
    </div>
    <div class="form-last">
      <div>
        <el-form-item>
          <el-button
            type="primary"
            class="btn-red"
            @click="submitForm('unionPayForm')"
            data-testid="submitUnionPayForm"
          >
            {{ $t('common.button.submit') }}
          </el-button>
        </el-form-item>
      </div>
    </div>
  </el-form>
</template>

<script>
import vUpload from '@/components/vUpload';
import { apiRegisterUnionPayCard_cp } from '@/resource';
import { validateNumber, valiAlphanumeric } from '@/util/validation';
import branchMixin from '@/mixins/bankBranch';

export default {
  name: 'unionPayRegister',
  mixins: [branchMixin],
  props: ['onSuccess'],
  data() {
    return {
      unionPayForm: {
        bankName: '',
        cardNumber: '',
        cardPhoneNumber: '',
        cardHolderName: '',
        province: '',
        city: '',
        branchName: '',
        nationalId: '',
        cardPhotoFile: '',
        branch: ''
      },
      unionPayRules: {
        bankName: [
          {
            required: true,
            message: this.$t('common.formValidation.bankNameReq'),
            trigger: 'change'
          }
        ],
        cardNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.cardReq'),
            trigger: 'blur'
          },
          {
            validator: validateNumber,
            trigger: 'blur'
          }
        ],
        cardPhoneNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.phoneNumberReq'),
            trigger: 'blur'
          },
          {
            validator: validateNumber,
            trigger: 'blur'
          }
        ],
        cardHolderName: [
          {
            required: true,
            message: this.$t('common.formValidation.cardOnNameReq'),
            trigger: 'blur'
          }
        ],
        province: [
          {
            required: true,
            trigger: 'change'
          }
        ],
        city: [
          {
            required: true,
            trigger: 'change'
          }
        ],
        branchName: [
          {
            required: true,
            message: this.$t('common.formValidation.bankBranchReq'),
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              if (!this.unionPayForm.branch) {
                const message = this.$t('common.formValidation.bankBranchReq');
                callback(new Error(message));
              }
              callback();
            },
            trigger: 'blur'
          }
        ],
        nationalId: [
          {
            required: true,
            message: this.$t('common.formValidation.nationaIdReq'),
            trigger: 'blur'
          },
          {
            validator: valiAlphanumeric,
            trigger: 'blur'
          }
        ],
        cardPhotoFile: [
          {
            required: true,
            message: this.$t('common.formValidation.filePhotoReq'),
            trigger: 'blur'
          }
        ]
      },
      bankNameLists: [
        '工商银行',
        '建设银行',
        '交通银行',
        '农业银行',
        '中国银行',
        '招商银行',
        '光大银行',
        '兴业银行',
        '中信银行',
        '民生银行',
        '广东发展银行',
        '深圳发展银行',
        '中国邮储银行',
        '浦发银行',
        '平安银行',
        '北京银行',
        '上海银行',
        '南京银行',
        '渤海银行',
        '长沙银行',
        '浙商银行',
        '杭州银行',
        '华夏银行',
        '宁波银行',
        '深圳农商行',
        '北京农商银行',
        '上海农村商业银行',
        '浙江稠州商业银行',
        '中国农村信用社'
      ],
      resultMessage: this.$t('paymentDetails.result.unionPayCardSuccessMessage'),
      isError: ''
    };
  },
  components: {
    vUpload
  },
  computed: {
    compoundProperty() {
      return this.unionPayForm.province && this.unionPayForm.city;
    }
  },
  watch: {
    compoundProperty(bool) {
      if (bool) this.isError = '';
    },
    'unionPayForm.province'(value) {
      this.unionPayForm.city = '';
      this.unionPayForm.branchName = `${this.unionPayForm.province}-${this.unionPayForm.city}-${this.unionPayForm.branch}`;
    },
    'unionPayForm.city'(value) {
      this.unionPayForm.branchName = `${this.unionPayForm.province}-${this.unionPayForm.city}-${this.unionPayForm.branch}`;
    },
    'unionPayForm.branch'(value) {
      this.unionPayForm.branchName = `${this.unionPayForm.province}-${this.unionPayForm.city}-${this.unionPayForm.branch}`;
    }
  },
  methods: {
    submitForm(formName) {
      const vm = this;
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.registerCard(this.unionPayForm)
            .then(resp => {
              console.log('resp', resp);
              if (resp.data.code == 0) {
                this.$emit('onSuccess', {
                  resultMessage: this.resultMessage
                });
              } else {
                return false;
              }
            })
            .catch(err => {
              this.$message({
                message: err,
                type: 'error'
              });
            });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    updateFileInfo(fileInfo) {
      this.unionPayForm.cardPhotoFile = fileInfo.fileList[0];
      this.$refs['unionPayForm'].validateField('cardPhotoFile');
      console.log('file path', this.unionPayForm.cardPhotoFile);
    },
    registerCard(card) {
      console.log('card body: ', card);
      return apiRegisterUnionPayCard_cp(card);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/payment/register.scss';
</style>
