<template>
  <div class="common-dialog-wrapper" ref="DialogVersion3">
    <el-dialog
      :visible="visible"
      top="0"
      @close="close"
      :append-to-body="needToBody"
      :fullscreen="needFullscreen || fullscreen"
      :class="extraClass"
    >
      <div slot="title">
        <slot name="header"></slot>
      </div>
      <div class="dialog-body">
        <slot></slot>
      </div>
      <div v-if="visibleFooter" slot="footer" class="dialog-footer">
        <div @click="showLiveChat" class="chat" v-if="!hideChat" data-testid="liveChat">
          <img src="@/assets/images/dialog/chat.png" alt />
          <div v-html="$t('common.liveChat.desc')"></div>
        </div>
        <div class="background"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'DialogVersion3',
  props: {
    visible: Boolean,
    visibleFooter: Boolean,
    hideChat: Boolean,
    needFullscreen: Boolean,
    needToBody: { type: Boolean, default: false },
    extraClass: { type: String, default: '' }
  },
  data() {
    return {
      fullscreen: false
    };
  },
  watch: {
    visible(bool) {
      if (bool) this.$nextTick(() => this.setFullscreen());
    }
  },
  created() {
    window.addEventListener('resize', this.setFullscreen);
  },
  destroyed() {
    window.removeEventListener('resize', this.setFullscreen);
  },
  methods: {
    close() {
      this.$emit('update:visible', false);
      this.$emit('close');
    },
    setFullscreen() {
      this.fullscreen = false;
      this.$nextTick(() => {
        const h = window.innerHeight;
        const elDialog = this.$refs.DialogVersion3;
        if (elDialog) {
          const dialogH = elDialog.getElementsByClassName('el-dialog')[0].offsetHeight;
          this.fullscreen = dialogH >= h ? true : false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/dialog/v3/dialog.scss';
</style>
