<template>
  <div id="paymentDetails">
    <div class="content-box">
      <div class="inner">
        <div class="main">
          <div class="form-wrapper" v-if="!success">
            <div class="form-box">
              <h4>{{ $t('paymentDetails.subHeader') }}</h4>
              <div
                class="bankTransferText"
                v-html="$t('paymentDetails.tip', { individualUserName: individualUserName })"
              ></div>

              <div class="form-inner">
                <!-- <el-form label-position="top" class="is-sticky">
                  <div class="payment_method">
                    <el-form-item :label="$t('paymentDetails.payMethod')" required>
                      <el-select
                        v-model="paymentMethod"
                        @change="changeView($event)"
                        :placeholder="$t('common.field.select')"
                        data-testid="paymentMethod"
                      >
                        <el-option
                          v-for="(method, index) in paymentMethods"
                          :key="method.value"
                          :label="$t(method.label)"
                          :value="index"
                          :data-testid="method.label"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </el-form> -->
                <component :is="view" @onSuccess="onSuccess"></component>
              </div>
            </div>
            <RegisterHistory></RegisterHistory>
          </div>
          <Result v-else>{{ resultMessage }}</Result>
          <Detail
            :display.sync="detailShow"
            :name="name"
            :creditCardNumber="creditCardNumber"
            :expireDate="expireDate"
          ></Detail>
          <UnionpayDetails :display.sync="unionPayCardDetailShow" :cardId="cardId"></UnionpayDetails>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UnionPayRegister from '@/components/payment/UnionPayRegister';
import RegisterHistory from '@/components/payment/RegisterHistory';
import Detail from '@/components/payment/details';
import UnionpayDetails from '@/components/payment/details/UnionpayDetails';
import Result from '@/components/Result';

export default {
  components: {
    UnionpayDetails,
    UnionPayRegister,
    RegisterHistory,
    Detail,
    Result
  },
  data() {
    return {
      view: '',
      success: false,
      detailShow: false,
      unionPayCardDetailShow: false,
      cardId: null,
      paymentMethod: '',
      paymentMethods: this.$config.paymentMethods,
      name: '',
      creditCardNumber: '',
      expireDate: '',
      individualUserName: this.$store.state.common.individualUserName,
      resultMessage: ''
    };
  },
  methods: {
    changeView(value) {
      this.view = this.paymentMethods[value].component;
    },
    onSuccess(data) {
      this.success = true;
      this.resultMessage = data.resultMessage;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/financeInfo.scss';
</style>
