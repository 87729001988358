<template>
  <div class="table-wrapper">
    <h3>{{ $t('paymentDetails.history.header') }}</h3>
    <div class="table-box">
      <el-table :data="displayData" style="width:100%">
        <el-table-column :label="$t('common.column.date')" min-width="120">
          <template slot-scope="scope">{{ scope.row.create_time | date }}</template>
        </el-table-column>
        <el-table-column :label="$t('common.column.type')" min-width="100">
          <template slot-scope="scope">{{ scope.row.payment_type | paymentType }}</template>
        </el-table-column>
        <el-table-column :label="$t('common.column.number')" min-width="120">
          <template slot-scope="scope">
            <u v-if="scope.row.payment_type == 1">
              <a @click.stop.prevent="showDetail(scope.$index, scope.row)" data-testid="showDetail">
                {{ scope.row.card_number }}
              </a>
            </u>
            <u v-else-if="scope.row.payment_type == 4">
              <a @click.stop.prevent="showUnionpayDetails(scope.$index, scope.row)" data-testid="showUnionpayDetails">
                {{ scope.row.card_number }}
              </a>
            </u>
            <span v-else>{{ scope.row.card_number }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('common.column.status')" min-width="120">
          <template slot-scope="scope">{{ scope.row.status_display | status | transactionStatus }}</template>
        </el-table-column>
        <el-table-column prop="processed_notes" :label="$t('common.column.comment')" min-width="120"></el-table-column>
      </el-table>
    </div>
    <Pagination v-bind:table-data="tableData" v-bind:display-data.sync="displayData"></Pagination>
  </div>
</template>

<script>
import { apiHistoryList } from '@/resource';
import Pagination from '@/components/Pagination';
export default {
  name: 'registerHistory',
  components: {
    Pagination
  },
  data() {
    return {
      tableData: [],
      displayData: []
    };
  },
  methods: {
    showDetail(index, row) {
      this.$parent.name = row.card_holder_name;
      this.$parent.creditCardNumber = row.card_number;
      this.$parent.expireDate = row.expiry;
      this.$parent.detailShow = true;
    },
    showUnionpayDetails(index, row) {
      this.$parent.cardId = row.id;
      this.$parent.unionPayCardDetailShow = true;
    }
  },
  mounted() {
    apiHistoryList({
      cardType: -1
    })
      .then(resp => {
        this.tableData = resp.data;
      })
      .catch(err => {});
  }
};
</script>

<style lang="scss" scoped>
.table-wrapper {
  border-style: solid;
  border-width: $border-width;
  border-color: $border-color;
  border-radius: 6px;
  background-color: $white;
  padding: 20px;
}
</style>
